import Notification, {
    NotificationType
} from '@naturehouse/design-system/components/atoms/notification/Notification';
import { NotificationMessageProps } from '@naturehouse/design-system/components/molecules/notification-message/NotificationMessage';
import notificationMessageTemplate from '@naturehouse/design-system/components/molecules/notification-message/NotificationMessage.html.njk';

export function createNotificationMessage(props: NotificationMessageProps): string {
    return notificationMessageTemplate.render(props);
}

export default function createNotification(
    type: NotificationType,
    content: string,
    delay = 5000
): void {
    let notificationContainer: HTMLElement | null = document.querySelector(
        '[data-role="notification-container"]'
    );

    if (!notificationContainer) {
        notificationContainer = document.createElement('div');
        notificationContainer.dataset.role = 'notification-container';
        notificationContainer.className = 'nh-notification-container';
        notificationContainer.dataset.position = 'top-right';
        document.body.appendChild(notificationContainer);
    }

    const notification: Notification = new Notification({
        type: type,
        content: content,
        delay: delay
    });

    notificationContainer.appendChild(notification);

    if (!notificationContainer) {
        document.body.appendChild(notificationContainer);
    }
}
