var nunjucks = require("nunjucks/browser/nunjucks-slim");
var env;
if (!nunjucks.currentEnv){
	env = nunjucks.currentEnv = new nunjucks.Environment([], undefined);
} else {
	env = nunjucks.currentEnv;
}
var configure = require("../../../../../nunjucks.config.js")(env);
var dependencies = nunjucks.webpackDependencies || (nunjucks.webpackDependencies = {});
dependencies["@naturehouse/design-system/components/atoms/anchor/Anchor.macro.njk"] = require( "@naturehouse/design-system/components/atoms/anchor/Anchor.macro.njk" );




var shim = require("/builds/natuurhuisje/nature.house/symfony/node_modules/nunjucks-loader/runtime-shim");


(function() {(nunjucks.nunjucksPrecompiled = nunjucks.nunjucksPrecompiled || {})["design-system/v3/components/molecules/notification-message/NotificationMessage.html.njk"] = (function() {
function root(env, context, frame, runtime, cb) {
var lineno = 0;
var colno = 0;
var output = "";
try {
var parentTemplate = null;
output += "<div class=\"nh-notification-message\">\n    <span class=\"nh-notification-message__title\">\n        ";
output += runtime.suppressValue(env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "title"),""), env.opts.autoescape);
output += "\n    </span>\n    <div class=\"nh-notification-message__content\">\n        ";
output += runtime.suppressValue(env.getFilter("safe").call(context, env.getFilter("default").call(context, runtime.contextOrFrameLookup(context, frame, "content"))), env.opts.autoescape);
output += "\n    </div>\n    ";
if(env.getTest("defined").call(context, runtime.contextOrFrameLookup(context, frame, "cta")) === true && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"label")) && env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"href"))) {
output += "\n        ";
env.getTemplate("@naturehouse/design-system/components/atoms/anchor/Anchor.macro.njk", false, "design-system/v3/components/molecules/notification-message/NotificationMessage.html.njk", false, function(t_2,t_1) {
if(t_2) { cb(t_2); return; }
t_1.getExported(function(t_3,t_1) {
if(t_3) { cb(t_3); return; }
context.setVariable("anchorTemplate", t_1);
var t_4;
t_4 = {};
frame.set("icon", t_4, true);
if(frame.topLevel) {
context.setVariable("icon", t_4);
}
if(frame.topLevel) {
context.addExport("icon", t_4);
}
if(env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"target"),"") == "_blank") {
var t_5;
t_5 = {"name": "external-link"};
frame.set("icon", t_5, true);
if(frame.topLevel) {
context.setVariable("icon", t_5);
}
if(frame.topLevel) {
context.addExport("icon", t_5);
}
;
}
output += runtime.suppressValue((lineno = 18, colno = 32, runtime.callWrap(runtime.memberLookup((t_1),"render"), "anchorTemplate[\"render\"]", context, [{"label": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"label"),"href": runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"href"),"target": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"target"),""),"classes": ["nh-notification-message__button"],"icon": runtime.contextOrFrameLookup(context, frame, "icon"),"iconPosition": "right","data": env.getFilter("default").call(context, runtime.memberLookup((runtime.contextOrFrameLookup(context, frame, "cta")),"data"),{})}])), env.opts.autoescape);
output += "\n    ";
})});
}
output += "\n</div>\n";
if(parentTemplate) {
parentTemplate.rootRenderFunc(env, context, frame, runtime, cb);
} else {
cb(null, output);
}
;
} catch (e) {
  cb(runtime.handleError(e, lineno, colno));
}
}
return {
root: root
};

})();
})();



module.exports = shim(nunjucks, env, nunjucks.nunjucksPrecompiled["design-system/v3/components/molecules/notification-message/NotificationMessage.html.njk"] , dependencies)